.dialog{
   
    padding: 10px;
    .MuiInputBase-input{
        color:#000000 !important;
      }
      
      .MuiFormLabel-root{
        color:#000000 !important;
      }
      
}

.button{
    float:right;
    margin: 5px;
}

.list{
    width: 600px;
}

.textField{
    width: 100%;
}

