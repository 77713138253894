.App {
  text-align: center;
  background-color: #282c34;
  color: white;
  padding: 5px;
  min-height: 100vh;
}



.App-header {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.main {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.main p{
  width: 60vw;
}

.App-link {
  color: #61dafb;
}

.recommendation{
  font-size: calc(8px + 2vmin);
}



.MuiOutlinedInput-notchedOutline{
  border-color: #ffffff !important;
}

.MuiFilledInput-root {
  border-bottom: 1px solid #ffffff !important;
}

.MuiInput-root {
  border-bottom: 1px solid #ffffff !important;
}

.MuiButton-root{
  margin: 7px !important;
}

.MuiPaper-root{
  width: 50%;
  margin: 7px auto 7px auto;
  /* left:25%; */
}



.Mui-disabled{
  color: #999999 !important;
}

textarea.Mui-disabled{
  color: #ffffff !important;
}

.MuiAutocomplete-root{
  padding: 7px;
}



.MuiSvgIcon-root{
  color:#888888 !important;
}

